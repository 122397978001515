<template>
  <div class="homeBox">
    <div class="homeMainBox">
      <div class="homeMain">
        <div class="homeMain_left"></div>
        <div class="homeMain_right">
          <!-- <div class="homeMain_right_item" @click="jump('integralmana')">
            <img src="../../assets/imgs/itemImg.png" />
            <p>积分管理</p>
          </div> -->
          <div class="homeMain_right_item" @click="jump('TestPage01')">
            <img src="../../assets/imgs/itemImg.png" />
            <p>认证审核</p>
          </div>
          <div class="homeMain_right_item" @click="jump('releaseentry')">
            <img src="../../assets/imgs/itemImg.png" />
            <p>商业发布</p>
          </div>
          <div class="homeMain_right_item" @click="jump('shareControl')">
            <img src="../../assets/imgs/itemImg.png" />
            <p>内容审核</p>
          </div>
          <div class="homeMain_right_item" @click="jump('useradmin')">
            <img src="../../assets/imgs/itemImg.png" />
            <p>系统管理</p>
          </div>
          <!-- <div class="homeMain_right_item" @click="jump('statistical')">
            <img src="../../assets/imgs/itemImg.png" />
            <p>统计</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data () {
    return {}
  },
};
</script>

<style scoped lang="scss">
.homeBox {
  background-image: url('../../assets/imgs/indexbg.png');
  background-size: 100% 100%;
  overflow: hidden;
}
.homeMainBox {
  width: 115vh;
  height: 50vh;
  margin: 0 auto;
  margin-top: 15vh;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cee6ff;
  .homeMain {
    width: 100%;
    height: 45vh;
    padding: 3vh;
    margin-top: 5vh;
    border: 1px solid #ccc;
    border-top: 2vh solid rgb(117, 177, 239);
    background-color: white;
    .homeMain_left {
      float: left;
      padding: 5vh;
      width: 55%;
      height: 100%;
      background-image: url('../../assets/imgs/imgBoxImg.png');
      background-size: 85% 100%;
      background-position: center;
      background-repeat: no-repeat;
      border-right: 1px dashed #ccc;
    }
    .homeMain_right {
      float: left;
      width: 45%;
      height: 100%;
      padding: 3vh;
      overflow: overlay;
      .homeMain_right_item {
        cursor: pointer;
        padding: 2vh;
        width: calc(50% - 8vh);
        height: calc(50% - 4vh);
        margin: 2vh 4vh;
        float: left;
        border-radius: 10px;
        transition: all 0.2s;
        box-shadow: 0px 5px 15px rgba(117, 177, 239, 0.5);
        img {
          width: 50%;
          height: auto;
        }
        p {
          color: #000;
          margin-top: 0.5vh;
        }
      }
      .homeMain_right_item:hover {
        box-shadow: 0px 5px 25px rgba(117, 177, 239, 1);
      }
    }
  }
}
</style>
